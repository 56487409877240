@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

.background {
  background-image: url("images/hexagon-pattern-extended.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow-y: auto;
  background-size: cover;
}

.navBar {
  z-index: 1;
  background-color: #282c34;
  display: flex;
  width: 100%;
  min-height: 6vh;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  border-bottom-style: solid;
  border-bottom-width: 2;
  border-bottom-color: black;
  color: white;
  font-size: calc(10px + 2vmin);
  cursor: pointer;
  user-select: none;
}

.mobileHeader {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  padding-left: 1em;
  padding-right: 1rem;
}

.icon {
  margin: 5px;
  justify-content: right;
}

.inlineNav {
  box-sizing: border-box;
  width: 100%;
  font-size: calc(5px + 2vmin);
  align-items: center;
  justify-content: space-evenly;
}

.brand {
  font-family: 'Architects Daughter';
}

.navItem {
  margin: 10px;
}


/* Use these with navbar component */
.selected {
  opacity: 1;
}

.notSelected {
  opacity: .25;
}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
}

.title {
  animation-name: appear;
  border-radius: 25px;
  background-color: darkcyan;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 200px;
  margin-top: 300px;
  margin-bottom: 400px;
  border-radius: 25px;
  padding-top: calc(50px + 2vmin);
  padding-bottom: calc(50px + 2vmin);
  padding-left: 50px;
  padding-right: 50px;
  opacity: 0.9;
  position: relative;
}

.title1 {
  font-size: calc(40px + 2vmin);
  color: black;
  font-weight: 1.5;
}

.title2 {
  font-size: calc(20px + 2vmin);
  color: black;
  font-weight: 1.5;
}

.contentTitle {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: calc(20px + 2vmin);
}

.contentSubTitle {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: calc(15px + 2vmin);
}

.content {
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  font-weight: 200;
  color: white;
}

.block {
  animation-name: appear;
  border-radius: 25px;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 200px;
  margin-top: 250px;
  padding: 50px;
  opacity: .75;
  min-width: 40%;
  max-width: 40%;
}

.image {
  margin: 20px;
  max-width: 40%;
  max-height: 40%;
}

.mobileImage {
  margin: 1rem;
  min-width: 100%;
  min-height: 100%;
}

.bottom {
  margin-bottom: 350px;
}

.show {
  animation-name: appear;
  animation-duration: 3s;
}

@keyframes appear {
  0% { opacity: 0; }
  100% { opacity: .9;}
}
